// @ts-nocheck
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import logoImage from '@/static/logo.png';

import '@/style/components/BigcapitalLoading.scss';

/**
 * Bigcapital logo loading.
 */
export default function BigcapitalLoading({ className }) {
  return (
    <div className={classNames('bigcapital-loading', className)}>
      <div class="center">
        <img src={logoImage} height={300} width={300} alt="logo" />
      </div>
    </div>
  );
}
